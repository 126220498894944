document.addEventListener("turbolinks:load", () => {
    let burger = document.querySelector('.burger')
    let burgerMobMenu = document.querySelector('.burger-mob-menu');
    let closeBurgerMenu = document.querySelector('.close-burger-mob');

    if (burger && burgerMobMenu && closeBurgerMenu) {
        burger.addEventListener('click',()=>{
            burgerMobMenu.classList.add('active');
        });
        closeBurgerMenu.addEventListener('click',()=>{
            burgerMobMenu.classList.remove('active');
        });
    };

    $('.clear_product_id').on('click', function () {
        $('#auth_product').val("");
        $('#auth_product_sign_in').val("");
    });
});

document.addEventListener("turbolinks:load", () => {
    let mySwiper = document.querySelector('.mySwiper');
    let thumbsSwiper = document.querySelector('.thumbsSwiper');

    if (mySwiper && thumbsSwiper) {
      const thumbsSwiper = new Swiper('.thumbsSwiper', {
          freeMode: true,
          loop: false,
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
      });

      const myswiper = new Swiper('.mySwiper', {
          loop: true,
          slidesPerView: 1,
          spaceBetween: 10,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
          thumbs: {
              swiper: thumbsSwiper,
         },
      });
    };
});
